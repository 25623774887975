import './Slider.css';

import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

const Slider = ({ backgroundImage, titleTexts,buttonstext }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const textArray = titleTexts; 
    let charIndex = 0;

    const typingInterval = setInterval(() => {
      setDisplayedText(textArray[currentTextIndex].slice(0, charIndex + 1));
      charIndex++;
      if (charIndex === textArray[currentTextIndex].length) {
        clearInterval(typingInterval); 
        setTimeout(() => {
          setCurrentTextIndex((currentTextIndex + 1) % textArray.length); 
        }, 3000); 
      }
    }, 50); 

    return () => clearInterval(typingInterval); 
  }, [currentTextIndex, titleTexts]);

  return (
    <section className="Main-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="Main-overlay"></div>
      <div className="Main-head-sec">
        <h1 className="Main-title">
          {displayedText}
          <span className="cursor">|</span>
        </h1>
      </div>
      <div className="Main-button-wrapper">
        
      <Link className="li-nav-link" to="/ContactUs"><button className="Main-button">{buttonstext}</button></Link>
        
      </div>
    </section>
  );
};

export default Slider;
