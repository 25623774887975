import "./Nav.css";

import React, { useEffect, useState } from "react";

const Nav = () => {
  const [activeSection, setActiveSection] = useState("");

  
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle click event for navigation
  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setActiveSection(sectionId);
    }
  };

  return (
    <nav className="scroll-nav">
      <ul>
        <li className={activeSection === "education-support" ? "active" : ""}>
          <a
            href="#education-support"
            onClick={(e) => handleNavClick(e, "education-support")}
          >
          Education Support for Parents and Kids
          </a>
        </li>
        <li className={activeSection === "community-leadership" ? "active" : ""}>
          <a
            href="#community-leadership"
            onClick={(e) => handleNavClick(e, "community-leadership")}
          >
            Community Leadership
          </a>
        </li>
        <li className={activeSection === "diversity-stem" ? "active" : ""}>
          <a
            href="#diversity-stem"
            onClick={(e) => handleNavClick(e, "diversity-stem")}
          >
          Diversity and pathways in STEM Education
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
