import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import FeedBack from './Components/Contact/FeedBack';
import Gallery from './Components/Gallery/Gallery';
import Home from './Components/Home/Home';
import Navbar from './Components/NavBar/Navbar';
import OurTeam from './Components/OurTeam/OurTeam';
import ScrollTop from './ScrollTop';
import Services from './Components/Services/Services';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <ScrollTop></ScrollTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/OurTeam" element={<OurTeam />} />
          <Route path="/ContactUs" element={<Contact />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Gallery" element={<Gallery />} />
        </Routes>
        <FeedBack />
      </BrowserRouter>
    </div>
  );
}

export default App;
