import "./Contact.css";

import FeedBack from "./FeedBack";
import Footer from "../Footer/Footer";
import React from "react";
import Slider from "../Home/Slider";
import bgs from "./bg.jpg";

const Contact = () => {
  return (
    <div>
    <Slider
    backgroundImage={bgs}
    titleTexts={[
      "Get in Touch with Mindshift Community ",
      "Get in Touch with Mindshift Community ",
    ]} buttonstext="Contact Us"
></Slider>

      
    

      <div className="responsive-container">
        <div className="iframe-container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScE-wefhFpVCEN6TTQq1aipqMzlVmC1E_XhIef7JyqxVezDNw/viewform?embedded=true"
            width="540"
            height="1179"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="iframe-content"
            className="iframe-content"
            allowFullScreen
          >
            Loading…
          </iframe>

          

          
        </div>
        <FeedBack></FeedBack>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Contact;
