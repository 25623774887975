import "bootstrap-icons/font/bootstrap-icons.css";
import "./FeedBack.css";

import React, { useEffect, useState } from "react";

const FeedBack = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  // Toggle feedback message visibility every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setShowMessage((prev) => !prev);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {/* Feedback Icon */}
      <div
        className="feedback-button-wrapper"
        onClick={handleToggleForm}
      >
        <span className="tooltip">Feedback</span>
        <div className="feedback-button">
          <i className="bi bi-chat-left-text"></i>
        </div>
      </div>

      {/* Feedback Sentence */}
      {showMessage && !isFormOpen && (
        <div className="feedback-message">Give your feedback!</div>
      )}

      {/* Google Form iframe Container */}
      {isFormOpen && (
        <div className="form-overlay">
          <div className="form-container">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLScOdLyXwWiyDcaLnmuyMRhDuHljO8T-qw5PR7xpjJmceRtfaw/viewform?embedded=true"
              width="100%"
              height="600px"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="google-form"
              className="form-content"
              allowFullScreen
            >
              Loading…
            </iframe>

            <button type="button" className="close form-close-button" aria-label="Close" onClick={handleToggleForm}>
  <span aria-hidden="true" style={{fontSize:'20px'}}>&times;</span>
</button>
           

            
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedBack;
