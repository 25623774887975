import "./Services.css";

import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import React from "react";
import Slider from "../Home/Slider";
import bg from './bg.jpg';

const Services = () => {
  return (
    <>
    <Slider
                backgroundImage={bg}
                titleTexts={[
                    "Tailored Community Services for Lasting Impact.",
                    "Tailored Community Services for Lasting Impact.",
                ]} buttonstext="Contact Us"
            ></Slider>
      <Nav />
      <div className="responsive-grid">
        {/* Card 1 */}
        <section id="education-support" className="section card card-left-content">
          <div className="card-content">
            <h2 className="card-title">
              Education Support for Parents and Kids
            </h2>
            <p className="card-description">
            Tailored mentoring and guidance for parents and students to make informed educational choices.
            </p>
            <p className="card-description">
            Customized program sessions designed for both parents and students, aimed at enhancing their
            ability to make well-informed choices regarding school opportunities, university education and strategies for post school opportunities. These
            programs will offer valuable perspectives grounded in factual data, along with personalized guidance that aligns with individual’s unique traits,
            familial context, aspirations, and long-term goals
            </p>
           
          </div>
          <div className="card-list">
            <ul>
              <li>Student and Parents Consultation</li>
              <li>Strategy Development</li>
              <li>Education Framework</li>

            </ul>
          </div>
        </section>

        {/* Card 2 */}
        <section id="community-leadership" className=" section card card-right-content">
          <div className="card-content">
            <h2 className="card-title">Community Leadership</h2>
            <p className="card-description">
            Workshops and programs to empower individuals to lead and make a positive impact in their communities.
            </p>
            <p className="card-description">
            Community leadership involves making a tangible difference in the lives of community members through
            dedication, empathy, and effective collaboration. At Mindshift Community, we are committed to designing and delivering evidence based programs that
            address community needs
            </p>
           
          </div>
          <div className="card-list">
            <ul className="sp">
              
              <li>Professional workshops for new migrants</li>
              <li>Self-Awareness and Support for Women.</li>
            </ul>
          </div>
        </section>

        {/* Card 3 */}
        <section id="diversity-stem" className=" section card card-left-content">
          <div className="card-content">
            <h2 className="card-title">Diversity and pathways in STEM Education</h2>
            <p className="card-description">
            Initiatives and programs to inspire and support diverse participation in STEM careers.
            </p>
            <p className="card-description">
            Our evidence-based programs and initiatives support pathways to STEM careers. We offer guidance and support to
            inspire students from diverse backgrounds to pursue STEM fields. We are dedicated to promoting STEM within diverse communities. However,
            there is a lack of data on the engagement of students from various diverse cohorts in STEM subjects. 
            </p>

           
          </div>
          <div className="card-list">
            <ul>
        
              <li>Fostering networking and collaboration.</li>
              <li>STEM Support and Networking</li>
              
            </ul>
          </div>
        </section>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Services;
