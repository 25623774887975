import Cards from './Cards';
import Footer from '../Footer/Footer';
import React from 'react'
import Section from './Section';
import Slider from './Slider'
import bgImg from './Assests/Home_bg.jpg';

function Home() {
  return (
    <div>
      <Slider  backgroundImage={bgImg}  
      titleTexts={[
        "Empowering communities through collaboration and expertise.",
        "Tailored projects, designed with and for the community.",
        "Community-driven solutions, crafted by experts.",
        "Workshops and activities, created by the community, for the community.",
        "Identifying needs, designing solutions, delivering impact."
      ] } buttonstext="Contact Us"></Slider>
      <Section></Section>
      <Cards></Cards>
      <Footer></Footer>
    </div>
  )
}

export default Home
