import "./Cards.css";

import { Link } from "react-router-dom";
import React from "react";
import sc_1 from "./Assests/service-1.png";
import sc_2 from "./Assests/service-2.png";
import sc_3 from "./Assests/service-3.png";

const App = () => {

  const cardData = [
    {
      title: "Education Support for Parents and Kids",
      description:
      " Guided sessions offer data-driven insights for informed education and career choices.",
      image: sc_1, 
      className: "education-support-card",
    },
    {
      title: "Community Leadership",
      description:
        "Creating community impact through empathy, collaboration, and  evidence-based programs.",
      image: sc_2,
      className: "community-leadership-card",
    },
    {
      title: "Diversity and pathways in STEM Education",
      description:
      "Programs guide diverse students to STEM, bridging gaps and shaping policies.",
      image: sc_3,
      className: "diversity-stem-card",
    },
  ];
  return (

    <>
    <section className="services-cards-section">
  {cardData.map((card, index) => (
    <div className={`service-card ${card.className}`} key={index}>
      <img src={card.image} alt={card.title} className="service-card-image" />
      <h3 className="service-card-title">{card.title}</h3>
      <p className="service-card-description">{card.description}</p>
      <Link className="li-nav-link" to="/Services"><p className="know">Know more &gt;</p></Link>
    </div>
  ))}
</section>

    
    </>
  );
};

export default App;
