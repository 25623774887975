import "./Section.css";

import React from "react";
import one from "./Assests/mission.png";
import three from "./Assests/service.jpg";
import two from "./Assests/two.png";

const Section = () => {
  return (
    <div className="unique-container">

      <section className="unique-mission-section">
        <div className="unique-mission-image">
          <img src={one} alt="Mission" />
        </div>
        <div className="unique-mission-content">
          <h2>Our Mission</h2>
          <p className="m-1">
          Building stronger communities involves fostering collaboration, trust, and shared goals among members, empowering them to address local needs and challenges together. This approach leads to  sustainable growth, resilience, and enhanced well-being for everyone involved.
          </p>
        </div>
      </section>

      {/* Expertise Section */}
      <section className="unique-expertise-section">
        <div className="unique-expertise-content">
          <h2>Expertise</h2>
          <p className="m-2">
          Creating stronger communities hinges on collaboration and expertise. By harnessing the strengths and knowledge of both communities and organizations, we cultivate environments that promote growth, support, and resilience. At Mindshift Community we are constantly seeking opportunities to engage with community members and subject matter experts to improve community outcomes.</p>        </div>
        <div className="unique-expertise-image">
          <img src={two} alt="Expertise" />
        </div>
      </section>

      {/* Services Section */}
      <section className="unique-services-section">
        <div className="unique-services-image">
          <img src={three} alt="Services" />
        </div>
        <div className="unique-services-content">
          <h2>Services</h2>
          <ul className="m-3">
            <li>
              Customized sessions designed by and for the community aimed at
              enhancing capability to make well-informed decisions.
            </li>
            <li>
              We run workshops for professional migrant women to provide
              support in their career journey.
            </li>
            <li>
              Customized sessions for parents and kids to provide evidence-based
              programs and workshops to support pathways to Science, Technology,
              Engineering, and Math's career options and enhance diversity in
              STEM.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Section;
